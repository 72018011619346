function _define_property(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
import { css } from "@pigment-css/react";
import { deviceQueries } from "@stubhub/broadway-foundation";
export var linksHeaderWrapper = css(function (param) {
  var theme = param.theme;
  return {
    marginBottom: theme.vars.space[150]
  };
});
export var linksGrid = css(function (param) {
  var theme = param.theme;
  var _obj;
  return _obj = {
    display: "grid",
    gap: theme.vars.space[150],
    gridTemplateColumns: "1fr 1fr 1fr 1fr"
  }, _define_property(_obj, "@media ".concat(deviceQueries.tablet), {
    gridTemplateColumns: "1fr 1fr"
  }), _define_property(_obj, "@media ".concat(deviceQueries.phone), {
    gridTemplateColumns: "1fr"
  }), _obj;
});
export var linkItemContainer = css(function (param) {
  var theme = param.theme;
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: 0,
    gap: theme.vars.space[150],
    padding: theme.vars.space[200],
    borderRadius: theme.vars.border.radius[200],
    borderWidth: theme.vars.border.width[25],
    borderStyle: "solid",
    borderColor: theme.vars.colors.neutral.borderSecondary,
    backgroundColor: theme.vars.colors.surface.primary,
    "&:hover": {
      backgroundColor: theme.vars.colors.neutral.secondaryHover
    },
    "&:active": {
      backgroundColor: theme.vars.colors.neutral.secondaryActive
    }
  };
});
export var linkItemLocation = css(function (param) {
  var theme = param.theme;
  return {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - ".concat(theme.vars.space[300], ")")
  };
});
export var linkItemStateProvinceName = css(function (param) {
  var theme = param.theme;
  return {
    color: theme.vars.colors.surface.textSecondary
  };
});