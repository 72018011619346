import styled, { css as styledComponentsCss } from "styled-components";
import { Button } from "@viagogo/core/ui";
import { css } from "@pigment-css/react";
import { EventGridItem } from "../event-grid-item";
export var GridWrapperUnorderedList = styled.ul.withConfig({
  componentId: "sc-406e9a33-0"
})(["color:", ";border-width:1px 1px 0 1px;", " border-style:solid;border-color:", ";border-radius:", " ", " ", " ", ";background-color:", ";", " @media only screen and (max-width:", "px){margin-left:-2%;margin-right:-2%;}", ";scroll-margin-top:", ";"], function (props) {
  return props.theme.colors.textPrimary;
}, function (param) {
  var $removeBorder = param.$removeBorder;
  return $removeBorder && styledComponentsCss(["border-width:0px;"]);
}, function (props) {
  return props.theme.colors.borderDisabled;
}, function (props) {
  return props.theme.spacing(3);
}, function (props) {
  return props.theme.spacing(3);
}, function (props) {
  return props.theme.spacing(1);
}, function (props) {
  return props.theme.spacing(1);
}, function (props) {
  return props.theme.colors.backgroundPrimary;
}, function (props) {
  return props.$eventGridV2 && styledComponentsCss(["background-color:", ";display:flex;flex-direction:column;gap:", ";border:none;"], props.theme.palette.surfaceSecondary, props.theme.spacing(2));
}, function (props) {
  return props.theme.media.breakpoints.sm;
}, function (props) {
  return props.negativeMargin && styledComponentsCss(["@media ", "{margin-left:", ";margin-right:", ";}"], function (param) {
    var media = param.theme.media;
    return media.phone;
  }, props.theme.spacing(-5), props.theme.spacing(-5));
}, function (param) {
  var theme = param.theme;
  return theme.spacing(2.5);
});
export var NoLocationGridTitle = styled.li.withConfig({
  componentId: "sc-406e9a33-1"
})(["padding:", ";", ";", " color:", ";", " ", " border-bottom:0px !important;"], function (props) {
  return props.theme.spacing(5, 3, 5, 5);
}, function (param) {
  var _param_theme = param.theme,
    _param_theme_typography = _param_theme.typography,
    getStyles = _param_theme_typography.getStyles,
    variant = _param_theme_typography.variant;
  return getStyles(variant.body1);
}, function (param) {
  var $biggerFont = param.$biggerFont,
    theme = param.theme;
  return $biggerFont && styledComponentsCss(["& strong{font-weight:", ";}"], theme.typography.fontWeight.bold);
}, function (props) {
  return props.theme.colors.textPrimary;
}, function (param) {
  var $flexBetween = param.$flexBetween;
  return $flexBetween && styledComponentsCss(["display:flex;justify-content:space-between;"]);
}, function (param) {
  var $greyBackground = param.$greyBackground,
    theme = param.theme;
  return $greyBackground && styledComponentsCss(["color:black;border-width:1px;border-style:solid;border-color:", ";border-radius:", " ", " 0 0;background-color:", ";"], theme.colors.borderPrimary, theme.borderRadius.l, theme.borderRadius.l, theme.palette.surfaceSecondary);
});
/* <li> implementation of GridTitleDiv for screen reader accessibility */
export var NoLocationGridTitleLi = styled.li.withConfig({
  componentId: "sc-406e9a33-2"
})(["padding:", ";", ";color:", ";", " ", ""], function (props) {
  return props.theme.spacing(5, 3, 5, 5);
}, function (param) {
  var _param_theme = param.theme,
    _param_theme_typography = _param_theme.typography,
    getStyles = _param_theme_typography.getStyles,
    variant = _param_theme_typography.variant;
  return getStyles(variant.body1);
}, function (props) {
  return props.theme.colors.textPrimary;
}, function (props) {
  return styledComponentsCss(["color:", ";border:none;padding-bottom:", ";padding-left:0px;"], props.theme.palette.textPrimaryOnSurface, props.theme.spacing(2));
}, function (param) {
  var $flexBetween = param.$flexBetween;
  return $flexBetween && styledComponentsCss(["display:flex;justify-content:space-between;"]);
});
export var MoreEventsButtonWrapper = styled.li.withConfig({
  componentId: "sc-406e9a33-3"
})(["display:flex;justify-content:center;margin-top:", ";"], function (props) {
  return props.theme.spacing(3);
});
export var MoreEventsButton = styled(Button).withConfig({
  componentId: "sc-406e9a33-4"
})(["height:32px;width:200px;padding:", ";border-radius:", ";"], function (props) {
  return props.theme.spacing(3, 4);
}, function (param) {
  var theme = param.theme;
  return theme.borderRadius.m;
});
export var CenteredDiv = styled.div.withConfig({
  componentId: "sc-406e9a33-5"
})(["text-align:center;"]);
export var StyledDiv = styled.div.withConfig({
  componentId: "sc-406e9a33-6"
})(["display:inline-flex;position:relative;margin-left:", ";button{padding:0;color:", ";}", ";"], function (props) {
  return props.withLeftMargin ? props.theme.spacing(1) : 0;
}, function (props) {
  return props.theme.colors.textLinkPrimary;
}, function (param) {
  var underline = param.underline;
  return !!underline && "text-decoration: underline";
});
export var BoldDiv = styled.div.withConfig({
  componentId: "sc-406e9a33-7"
})(["", ";", ""], function (param) {
  var _param_theme = param.theme,
    _param_theme_typography = _param_theme.typography,
    getStyles = _param_theme_typography.getStyles,
    variant = _param_theme_typography.variant,
    fontWeight = _param_theme_typography.fontWeight;
  return getStyles(variant.body1, fontWeight.bold);
}, function (param) {
  var $inline = param.$inline;
  return $inline && styledComponentsCss(["display:inline;"]);
});
export var PlainTextDate = styled.div.withConfig({
  componentId: "sc-406e9a33-8"
})(["white-space:pre;margin-left:", ";", ""], function (props) {
  return props.theme.spacing(1);
}, function (param) {
  var $boldFont = param.$boldFont,
    theme = param.theme;
  return $boldFont && styledComponentsCss(["color:black;font-weight:", ";"], theme.typography.fontWeight.bold);
});
export var BoldTitleText = styled.span.withConfig({
  componentId: "sc-406e9a33-9"
})(["font-weight:", ";"], function (param) {
  var theme = param.theme;
  return theme.typography.fontWeight.bold;
});
export var StyledEventGridItem = styled(EventGridItem).withConfig({
  componentId: "sc-406e9a33-10"
})(["position:relative;", ";"], function (param) {
  var $isHighlightedItem = param.$isHighlightedItem,
    theme = param.theme;
  return $isHighlightedItem && styledComponentsCss(["border-style:solid;border-width:0px !important;border-color:", ";"], theme.palette.borderBrandPrimary);
});
export var AllEventsGridTitle = styled.span.withConfig({
  componentId: "sc-406e9a33-11"
})(["& strong{font-weight:", ";}"], function (param) {
  var theme = param.theme;
  return theme.typography.fontWeight.bold;
});
export var ResetFiltersContainer = styled.div.withConfig({
  componentId: "sc-406e9a33-12"
})(["display:flex;flex-direction:column;gap:", ";margin-top:", ";button{width:fit-content;}> div{", ";}"], function (param) {
  var theme = param.theme;
  return theme.spacing(3);
}, function (param) {
  var theme = param.theme;
  return theme.spacing(6);
}, function (param) {
  var _param_theme = param.theme,
    _param_theme_typography = _param_theme.typography,
    getStyles = _param_theme_typography.getStyles,
    variant = _param_theme_typography.variant;
  return getStyles(variant.subtitle1);
});
export var NoLocalEventDiv = styled.div.withConfig({
  componentId: "sc-406e9a33-13"
})(["", ";text-align:", ";margin-bottom:", ";margin-top:", ";"], function (param) {
  var _param_theme = param.theme,
    _param_theme_typography = _param_theme.typography,
    getStyles = _param_theme_typography.getStyles,
    variant = _param_theme_typography.variant;
  return getStyles(variant.body1);
}, function (param) {
  var $alignLeft = param.$alignLeft;
  return $alignLeft ? "left" : "center";
}, function (param) {
  var theme = param.theme;
  return theme.spacing(6);
}, function (param) {
  var theme = param.theme,
    showRedesignedDateLocationFilter = param.showRedesignedDateLocationFilter;
  return showRedesignedDateLocationFilter ? theme.spacing(16) : theme.spacing(4);
});
export var EmptyLocalHeaderDiv = styled.div.withConfig({
  componentId: "sc-406e9a33-14"
})(["", ";"], function (param) {
  var _param_theme = param.theme,
    _param_theme_typography = _param_theme.typography,
    getStyles = _param_theme_typography.getStyles,
    variant = _param_theme_typography.variant;
  return getStyles(variant.headerMedium);
});
export var EmptyLocalSubheaderDiv = styled.div.withConfig({
  componentId: "sc-406e9a33-15"
})(["", ";margin-top:", ";margin-bottom:", ";"], function (param) {
  var _param_theme = param.theme,
    _param_theme_typography = _param_theme.typography,
    getStyles = _param_theme_typography.getStyles,
    variant = _param_theme_typography.variant;
  return getStyles(variant.titleSmall);
}, function (param) {
  var theme = param.theme;
  return theme.spacing(3);
}, function (param) {
  var theme = param.theme;
  return theme.spacing(3);
});
export var EmptyLocalButtonsWrapperDiv = styled.div.withConfig({
  componentId: "sc-406e9a33-16"
})(["display:flex;gap:", ";width:100%;overflow-x:auto;"], function (param) {
  var theme = param.theme;
  return theme.spacing(2.5);
});
export var EventsGridContainer = styled.div.withConfig({
  componentId: "sc-406e9a33-17"
})(["margin-top:", ";", ""], function (param) {
  var theme = param.theme;
  return theme.spacing(4);
}, function (props) {
  return props.$isFixedMobileMargin && styledComponentsCss(["@media ", "{margin-top:", ";}"], function (themeProps) {
    return themeProps.theme.media.phone;
  }, function (themeProps) {
    return themeProps.theme.spacing(2);
  });
});
export var EventsGridContainerNoMargin = styled(EventsGridContainer).withConfig({
  componentId: "sc-406e9a33-18"
})(["margin-top:0px;"]);
export var PaginationWrapper = styled.div.withConfig({
  componentId: "sc-406e9a33-19"
})(["display:flex;flex-direction:column;align-items:center;justify-content:space-between;gap:", ";margin-top:", ";margin-bottom:", ";& > *:only-child{margin-left:auto;margin-right:auto;}&:empty{margin:0;}"], function (param) {
  var theme = param.theme;
  return theme.spacing(5);
}, function (param) {
  var theme = param.theme;
  return theme.spacing(5);
}, function (param) {
  var theme = param.theme;
  return theme.spacing(4);
});
export var seeEventsInAllLocationsButtonLinkStyles = {
  root: css({
    width: "100%"
  })
};