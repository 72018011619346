import { css } from "@pigment-css/react";
export var imageCarousel = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start"
});
export var styledImage = css(function (param) {
  var theme = param.theme;
  return {
    width: "100%",
    borderRadius: theme.border.radius[100],
    transition: "opacity 1.25s ease-in-out",
    opacity: 1
  };
});
export var inactiveImage = css({
  position: "absolute",
  left: "0",
  top: "0",
  opacity: 0,
  transition: "opacity 1.25s ease-in-out"
});
export var carouselTitle = css(function (param) {
  var theme = param.theme;
  return {
    marginTop: theme.vars.space[150]
  };
});
export var carouselSection = css(function (param) {
  var theme = param.theme;
  return {
    marginTop: theme.vars.space[150],
    position: "relative"
  };
});
export var carouselControls = css(function (param) {
  var theme = param.theme;
  return {
    display: "flex",
    gap: theme.vars.space[100],
    marginTop: theme.vars.space[150],
    alignSelf: "center"
  };
});
export var carouselControlButton = css(function (param) {
  var theme = param.theme;
  return {
    width: theme.vars.space[100],
    height: theme.vars.space[100],
    borderRadius: "50%",
    backgroundColor: theme.colors.neutral.borderPrimary,
    cursor: "pointer"
  };
});
export var carouselControlActiveStyle = css(function (param) {
  var theme = param.theme;
  return {
    backgroundColor: theme.colors.text.darkest
  };
});