import styled, { css as styledComponentsCss } from "styled-components";
import { Col, Container, Row } from "@viagogo/core/ui";
import { css } from "@pigment-css/react";
export var EventGridV2Background = styled(Container).withConfig({
  componentId: "sc-62a52116-0"
})(["background-color:", ";@media ", "{padding:0;}"], function (props) {
  return props.theme.palette.surfaceSecondary;
}, function (param) {
  var media = param.theme.media;
  return media.desktop;
});
export var StyledRow = styled(Row).withConfig({
  componentId: "sc-62a52116-1"
})(["padding-top:", ";position:relative;", ""], function (props) {
  return props.$removeTopSpacing ? 0 : props.theme.spacing(props.$mobileGlassHeaderPadding ? 3.5 : 5);
}, function (param) {
  var theme = param.theme,
    $removeTopSpacing = param.$removeTopSpacing,
    $reducedPadding = param.$reducedPadding;
  return $reducedPadding && styledComponentsCss(["padding-top:", ";"], $removeTopSpacing ? 0 : theme.spacing(4));
});
export var StyledCol = styled(Col).withConfig({
  componentId: "sc-62a52116-2"
})(["display:flex;& > div{flex:1;&:not(:empty){margin-bottom:", ";}}column-gap:", ";"], function (param) {
  var theme = param.theme;
  return theme.spacing(4);
}, function (props) {
  return props.theme.spacing(5);
});
export var elevatedTextPrimary = css(function (param) {
  var theme = param.theme;
  return {
    color: theme.vars.colors.elevated.textPrimary
  };
});